@import url("https://fonts.googleapis.com/css2?family=Allerta+Stencil&family=Bungee+Hairline&family=Bungee+Outline&family=Cuprum&family=Monofett&family=Monoton&family=Orbitron&family=Raleway+Dots&display=swap");

body {
  background-image: url("https://images.pexels.com/photos/62693/pexels-photo-62693.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.about-image-container {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

/*----------Navbar styling-----------*/

/*--------overwrighting bootstrap navbar styles-------*/
ul.nav li a,
ul.nav li a:visited {
  color: #000 !important;
}

ul.nav li a:hover,
ul.nav li a:active {
  color: #000 !important;
}

ul.nav li.active a {
  color: #000 !important;
}

.nav-tabs .nav-item .nav-link.active {
  border-color: transparent;
  background-color: transparent;
}

.nav-tabs {
  border-bottom: transparent;
}

.nav-tabs {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  font-size: 40px;
}

.about-title {
  font-family: "Bungee Hairline";
  text-align: center;
  font-size: 60px;
  padding-bottom: 30px;
  font-weight: 800;
}

.about-name {
  font-family: "Monofett";
  font-size: 80px;
  text-align: center;
  padding-bottom: -50px;
}

.nav-link {
  font-family: "Bungee Hairline";
  text-decoration: none;
  font-weight: bolder;
  color: black;
  display: flex;
  justify-content: space-between;
}

.about-text {
  font-family: "avenir";
  font-size: 20px;
  padding-bottom: 250px;
  /* padding-top: 5px; */
  /* padding-left:350px;
  padding-right: 350px; */
  /* background-color: white; */
}

.paragraph{
  background-color: white;
  border-radius: 20px 20px;
  padding: 16px;
  font-weight: 500;
}

.resume-link {
  font-family: "Monofett";
  text-align: center;
  font-size: 70px;
}

.resume-link2 {
  color: #000;
  text-decoration: none !important;
}

/*----------------Contact page styling----------------*/
.contact-me {
  font-family: "Monofett";
  font-weight: bolder;
  text-align: center;
  padding-top: 25px;
  font-size: 80px;
}

/*-------------resume page styling-------------*/

.resume-heading {
  font-family: "bungee hairline";
  font-weight: 900;
  /* background-color: transparent; */
  display: flex;
  justify-content: center;
  font-size: 25px;
  background-color: black;
  color: white;
  margin: 20px;
}

.lang-list {
  font-family: "avenir";
  text-align: center;
  text-decoration: none;
  list-style-type: none;
  padding-right: 50px;
}

/*--------project styling---------------*/

.portfolio-title {
  font-family: "Monofett";
  text-align: center;
  font-size: 70px;
  padding-bottom: 25px;
  padding-top: 45px;
}

.project-style {
  font-family: "Monofett";
  font-weight: bolder;
  text-align: center;
  padding-top: 50px;
  font-size: 80px;
}

a.btn {
  margin-right: 5px;
  background-color: lightgreen;
  color: black;
  border-color: black;
}

.card {
  text-align: center;
  padding-top: 65px;
  font-family: "avenir";
  color: black;
  border: black 2px;
}

.border-card-style {
  border: black 5px;
}

.card:visited {
  color: black;
}

.card-text-style {
  color: black;
  font-size: 40px;
}

.card,
.card-body {
  max-width: 100%;
  height: auto;
}

.gh-link {
  padding-bottom: 50px;
}

.project-card-description {
  font-size: 25px;
}
.github-link {
  font-size: 20px;
  color: black;
}

/***-----puts logos vertical if left out------*/
.navbar-text {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}

/*---------Media queries--------------*/

@media screen and (max-width: 600px) {
  body {
    margin: 0 auto;
    margin-top: 20px;
  }
}

@media screen and (max-width: 375px) {
  .responsive-cards {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 700px) {
  #formerStyle {
    /* background-color: hotpink; */
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    grid-gap: 5rem;
  }
  .card-img-top {
    max-width: 300px;
  }
  .card-body {
    max-width: max-content;

  }
  .paragraph {
    margin: 10%;
  }
}

.former-style {
  width: 55rem;
  background-color: transparent;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-gap: 5rem;
  margin: 5%;
  margin-left: 20%;
}

.card-body {
  background-color: lightgrey;
  border: solid black 2px;
  padding: 25px;
  width: 300px;
}
